import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {session} from "../api/ApiSession";
import {QueryString} from "../common/api/model/QueryString";
import {TranslationPortalFile} from "../utils/constants";
import {Button, ButtonSize, Footer, SvgSirdataAudienceLogoWhite} from "@sirdata/ui-lib";
import {PORTAL} from "../utils/Portal";
import {usePreprod} from "../common/utils/helper";
import {Wrapper} from "../common/component/widget";
import {pathAccess} from "../common/utils/constants";
import {usePortalContext} from "../common/context/PortalContext";

function Login() {
    const history = useHistory();
    const {t: textLogin} = useTranslation(TranslationPortalFile.LOGIN);
    const {portalSetting} = usePortalContext();

    const goToAccount = (path: string) => () => {
        const accountRoot = (usePreprod ? "https://account-preprod.sirdata.io" : "https://account.sirdata.io");
        window.location.href = `${accountRoot}/${path}?${QueryString.build({[QueryString.ORIGIN]: PORTAL.origin.name})}`;
    };

    useEffect(() => {
        if (session) {
            (async () => {
                try {
                    const isLogged = await session.isLogged();
                    if (!isLogged) {
                        return;
                    }

                    const account = await session.getAccount();
                    if (!account.hasAccess(PORTAL.origin.name)) {
                        history.push(pathAccess);
                        return;
                    }

                    history.push(PORTAL.defaultPath);
                } catch {
                    session.clean();
                }
            })();
        }
    }, [history]);

    const buildProductListItems = () => (
        <div className="login-items">
            <ul>
                {(textLogin("teaser.items", {returnObjects: true}) as string[]).map((item) => (
                    <li key={item}>{item}</li>
                ))}
            </ul>
        </div>
    );

    const buildFooter = () => (
        <Footer
            legalNotice={portalSetting.footer.legal_notice}
            cookiesSettings={portalSetting.footer.cookies_settings}
            items={portalSetting.footer.items.map((item) => ({label: item.label, path: item.path}))}
        />
    );

    return (
        <Wrapper>
            <div className="login-left">
                <div className="login-left-content">
                    <div className="main-logo">
                        <SvgSirdataAudienceLogoWhite/>
                    </div>
                    {buildProductListItems()}
                </div>
            </div>
            <div className="login-right">
                <div className="login-right__content">
                    <h1 className="login-right__content__title">{textLogin("login.title")}</h1>
                    <section className="login-right__content__section">
                        <div className="login-right__content__section__text">{textLogin("login.description")}</div>
                        <Button size={ButtonSize.BIG} onClick={goToAccount("login")}>{textLogin("login.buttons.login")}</Button>
                    </section>
                    <section className="login-right__content__section">
                        <div className="login-right__content__section__text">{textLogin("login.not_yet_registered")}</div>
                        <Button size={ButtonSize.BIG} onClick={goToAccount("register")}>{textLogin("login.buttons.register")}</Button>
                    </section>
                    <div className="logos">
                        <div className="logo">
                            <img src="/images/logo_iab_europe.png" alt="Logo IAB Europe"/><span>IAB TCF 2.0</span>
                        </div>
                        <div className="logo">
                            <img src="/images/logo_iab_tech_lab.png" alt="Logo IAB Tech Lab"/><span>IAB Tech Lab</span>
                        </div>
                        <div className="logo">
                            <img src="/images/logo_gdpr.png" alt="Logo GDPR"/><span>GDPR</span>
                        </div>
                        <div className="logo">
                            <img src="/images/logo_ccpa.png" alt="Logo CCPA"/><span>CCPA</span>
                        </div>
                        <div className="logo">
                            <img src="/images/logo_prebiid.png" alt="Logo Prebid"/><span>Prebid</span>
                        </div>
                    </div>
                </div>
                <div className="login-right__footer">
                    {buildFooter()}
                </div>
            </div>
            <div className="login-bottom">
                {buildProductListItems()}
                {buildFooter()}
            </div>
        </Wrapper>
    );
}

export default Login;
