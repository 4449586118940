import {createBrowserHistory} from "history";
import React, {FunctionComponent, Suspense, useEffect, useState} from "react";
import {Redirect, Route, Router, Switch} from "react-router-dom";
import {
    pathContextualCategory,
    pathContextualCustom,
    pathContextualCustomCategory,
    pathContextualCustomCategoryKeywords,
    pathContextualCustomCategoryRelevancy,
    pathDataSelection,
    pathDataSelectionWithPath,
    pathDeal,
    pathExplore,
    pathFavorites,
    pathFavoritesFolder,
    pathFavoritesFolderPublic,
    pathPartners,
    pathSearch,
    pathUserSegment
} from "./utils/constants";
import PrivateRoute from "./component/guard/PrivateRoute";
import {Authorization} from "./api/model/account/Authorization";
import {SirdataApiEvent} from "./common/api/CommonApiClient";
import {Error403, Error404, Error500} from "./common/screen/index";
import {ScrollToTop} from "@sirdata/ui-lib";
import {session} from "./api/ApiSession";
import {pathAccess, pathError403, pathError404, pathError500, pathHome, pathLegalNotice, pathLogin, pathSso} from "./common/utils/constants";
import ModalCampaignBrief from "./component/modal/campaign-brief/ModalCampaignBrief";
import ModalCuratedDealRequest from "./component/modal/ModalCuratedDealRequest";
import {
    Access,
    ContextualCategory,
    ContextualCustom,
    ContextualCustomCategory,
    ContextualCustomCategoryKeywords,
    ContextualCustomCategoryRelevancy,
    DataSelections,
    DataSelectionsDetails,
    Deal,
    Explore,
    FavoritesFolder,
    FavoritesFolderPublic,
    FavoritesIndex,
    Home,
    LegalNotice,
    Login,
    Partners,
    Search,
    Sso,
    UserSegment
} from "./screen";
import {PortalSetting} from "./common/api/model/portal-setting/PortalSetting";
import {PortalContextProvider} from "./common/context/PortalContext";

const history = createBrowserHistory();

const App: FunctionComponent = () => {
    const [portalSetting, setPortalSetting] = useState<PortalSetting>(new PortalSetting());

    useEffect(() => {
        (async () => {
            try {
                setPortalSetting(await session.getPortalSetting());
            } catch (e) {
            }
        })();
    }, []);

    session.on(SirdataApiEvent.eventNotFound, () => {
        history.replace(pathError404);
    });

    return (
        <React.StrictMode>
            <Suspense fallback={<></>}>
                <PortalContextProvider portalSetting={portalSetting}>
                    <Router history={history}>
                        <ScrollToTop/>
                        <Switch>
                            <Route exact path={pathSso} component={Sso}/>
                            <Route exact path={pathAccess} component={Access}/>
                            <Route exact path={pathError403} component={Error403}/>
                            <Route exact path={pathError404} component={Error404}/>
                            <Route exact path={pathError500} component={Error500}/>

                            <Route exact path="/" component={() => <Redirect to={pathLogin}/>}/>
                            <Route exact path={pathLogin} component={Login}/>
                            <PrivateRoute exact path={pathHome} component={Home} requiredAuthorization={undefined}/>
                            <PrivateRoute exact path={pathExplore} component={Explore} requiredAuthorization={undefined}/>
                            <PrivateRoute exact path={pathSearch} component={Search} requiredAuthorization={undefined}/>
                            <PrivateRoute exact path={pathContextualCategory} component={ContextualCategory} requiredAuthorization={undefined}/>
                            <PrivateRoute exact path={pathContextualCustomCategoryKeywords} component={ContextualCustomCategoryKeywords} requiredAuthorization={Authorization.CUSTOM}/>
                            <PrivateRoute exact path={pathContextualCustomCategoryRelevancy} component={ContextualCustomCategoryRelevancy} requiredAuthorization={Authorization.CUSTOM}/>
                            <PrivateRoute exact path={pathContextualCustom} component={ContextualCustom} requiredAuthorization={Authorization.CUSTOM}/>
                            <PrivateRoute exact path={pathContextualCustomCategory} component={ContextualCustomCategory} requiredAuthorization={Authorization.CUSTOM}/>
                            <PrivateRoute exact path={pathDataSelection} component={DataSelections} requiredAuthorization={undefined}/>
                            <PrivateRoute exact path={pathDataSelectionWithPath} component={DataSelectionsDetails} requiredAuthorization={undefined}/>
                            <PrivateRoute exact path={pathDeal} component={Deal} requiredAuthorization={undefined}/>
                            <PrivateRoute exact path={pathPartners} component={Partners} requiredAuthorization={undefined}/>
                            <PrivateRoute exact path={pathFavorites} component={FavoritesIndex} requiredAuthorization={undefined}/>
                            <PrivateRoute exact path={pathFavoritesFolder} component={FavoritesFolder} requiredAuthorization={undefined}/>
                            <PrivateRoute exact path={pathFavoritesFolderPublic} component={FavoritesFolderPublic} requiredAuthorization={undefined}/>
                            <PrivateRoute exact path={pathUserSegment} component={UserSegment} requiredAuthorization={undefined}/>
                            <Route exact path={pathLegalNotice} component={LegalNotice}/>

                            <Route component={() => <Redirect to={pathError404}/>}/>
                        </Switch>
                    </Router>
                    <ModalCampaignBrief/>
                    <ModalCuratedDealRequest/>
                </PortalContextProvider>
            </Suspense>
        </React.StrictMode>
    );
};

export default App;
