import React, {useEffect, useState} from "react";
import {CustomCategory} from "../api/model/category/custom/CustomCategory";
import {useTranslation} from "react-i18next";
import {pathContextualCustom, pathExplore, pathPartners, TranslationPortalFile} from "../utils/constants";
import {useHistory, useLocation} from "react-router-dom";
import {session} from "../api/ApiSession";
import {CustomCategoryConfigStep} from "../api/model/category/custom/CustomCategoryConfigStep";
import {Segment} from "../api/model/segment/Segment";
import {MEDIA_PARTNERS} from "../api/model/Partner";
import {PlatformLogo, TagCookieless, TagEuid, TagKantarMedia, TagUserSegmentType} from "../component/snippet/index";
import {getSegmentStepFromValue} from "../api/model/segment/SegmentStepSize";
import {Locale} from "../common/utils/Locale";
import {Action, Box, BoxProps, Button, ButtonLink, ButtonLinkStyle, ButtonSize, ButtonStyle, ContentBlock, FlexContent, FlexContentDirection, FlexContentLayout, FlexContentMobileDirection, FlexContentSpacing, Icon, IconTooltip, LayoutRows, Loadable, Paragraph} from "@sirdata/ui-lib";
import {Formatter} from "../common/utils/Formatter";
import {MainContent, Wrapper} from "../common/component/widget";
import {UIEventManager} from "../common/utils/UIEventManager";
import {HOME_PLATFORMS, TaxonomyPlatform} from "../api/model/taxonomy/TaxonomyPlatform";
import {News} from "../common/api/model/portal/News";
import {ModalContactUiEvent} from "../common/component/modal/ModalContact";
import {MainHeader, NotificationCarousel} from "../common/component/snippet";
import {pathHome} from "../common/utils/constants";
import {SegmentDataType} from "../api/model/segment/SegmentDataType";

function Home() {
    const {t: textHome} = useTranslation(TranslationPortalFile.HOME);
    const history = useHistory();
    const {search} = useLocation();

    const [isSegmentsLoading, setSegmentsLoading] = useState(true);
    const [isNewsesLoading, setNewsesLoading] = useState(true);
    const [isCustomInProgressLoading, setCustomInProgressLoading] = useState(true);

    const [segments, setSegments] = useState<Segment[]>([]);
    const [latestNewses, setLatestNewses] = useState<News[]>([]);
    const [currentCustomCategories, setCurrentCustomCategories] = useState<CustomCategory[]>([]);

    const LATEST_NEWSES_COUNT = 5;
    const CUSTOM_CATEGORIES_COUNT = 3;

    useEffect(() => {
        (async function () {
            try {
                const segments = await session.restAudiencePortal.getHomeAudiences();
                setSegments(segments);

                const latestNewses = await session.getNewses();
                setLatestNewses(latestNewses.filter(({language}) => Locale.getCurrentLocale() === language).slice(0, LATEST_NEWSES_COUNT));
                setNewsesLoading(false);

                const customCategories = await session.restCustomCategory.list();
                if (customCategories.length > 0) {
                    setCurrentCustomCategories(customCategories.filter(({pending}) => pending).slice(0, CUSTOM_CATEGORIES_COUNT));
                }
                setCustomInProgressLoading(false);
            } catch (e) {
                console.error("Failed to load data:", e);
            } finally {
                setSegmentsLoading(false);
                setCustomInProgressLoading(false);
            }
        })();
    }, []);

    useEffect(() => {
        if (search && search.includes("contact")) {
            UIEventManager.emit(ModalContactUiEvent, {});
            history.replace(pathHome);
        }
    }, [search, history]);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent>
                <LayoutRows cssClass={"home"}>
                    <FlexContent direction={FlexContentDirection.ROW} layout={FlexContentLayout.TWO_COLUMNS_WIDE_LEFT} spacing={FlexContentSpacing.LARGE_PLUS} mobileDirection={FlexContentMobileDirection.COLUMN}>
                        <LayoutRows>
                            <NotificationCarousel cssClass="home__notification-carousel"/>
                            <Loadable loading={isSegmentsLoading} loaderOptions={{cssClass: "audiences_loader"}}>
                                {!!segments.length && (
                                    <ContentBlock
                                        header={{title: {label: textHome("latest_segments.title").toUpperCase(), icon: {name: "groups_2"}}}}
                                        footer={{link: pathExplore, label: textHome("latest_segments.see_all_segments")}}
                                    >
                                        <Box cssClass={"home__audiences"} {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                            {Array.from(Array(3)).map((_, index) => {
                                                const audiencesRow = `audiences-row-${index}`;
                                                return (
                                                    <div key={audiencesRow} className="audiences__row">
                                                        {segments.slice(index * 2, (index * 2) + 2).map((segment) =>
                                                            <div key={segment.id} className="audiences__item">
                                                                <div className="audiences__item__column">
                                                                    <div className="audiences__item__row">
                                                                        <div className="audiences__item__vertical">
                                                                            {segment.top_path_element?.name}
                                                                        </div>
                                                                        <div className="audiences__item__infos">
                                                                            <span className="audiences__item__info">
                                                                                <Icon name="equalizer" cssClass="audiences__item__info__icon"/>
                                                                                <span className="audiences__item__info__value">
                                                                                    {getSegmentStepFromValue(segment.step_volume)}
                                                                                </span>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="audiences__item__row">
                                                                        <a className="audiences__item__name" href={segment.getRoute()}>
                                                                            <span>{segment.name}</span>
                                                                            {segment.description &&
                                                                            <IconTooltip
                                                                                icon={{name: "info", outlined: true}}
                                                                                text={segment.description}
                                                                                cssClass={"audiences__item__name__icon"}
                                                                            />
                                                                            }
                                                                        </a>
                                                                    </div>
                                                                    <div className="audiences__item__row">
                                                                        <div className="audiences__item__tags">
                                                                            <TagUserSegmentType type={segment.data_type}/>
                                                                            {segment.cookieless && <TagCookieless/>}
                                                                            {segment.hasDataType(SegmentDataType.MODELING) && <TagKantarMedia/>}
                                                                            {segment.hasLink(TaxonomyPlatform.THE_TRADE_DESK_EUID) && <TagEuid/>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </Box>
                                    </ContentBlock>
                                )}
                            </Loadable>
                            <ContentBlock header={{title: {label: textHome("platforms.title").toUpperCase(), icon: {name: "share"}}}}>
                                <Box cssClass={"home__platforms"} {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                    {HOME_PLATFORMS.map((platform) =>
                                        <div key={platform.name} className="platform-logo">
                                            <PlatformLogo platform={platform}/>
                                        </div>
                                    )}
                                </Box>
                            </ContentBlock>
                            <ContentBlock
                                header={{title: {label: textHome("partners.title").toUpperCase(), icon: {name: "partner_exchange"}}}}
                                footer={{link: pathPartners, label: textHome("partners.see_all_partners")}}
                            >
                                <Box cssClass={"home__partners"} {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                    {MEDIA_PARTNERS.map((partner) =>
                                        <div key={partner.name} className="partner-logo">
                                            <img key={partner.img} src={partner.img} alt={`Logo ${partner.name}`}/>
                                        </div>
                                    )}
                                </Box>
                            </ContentBlock>
                        </LayoutRows>
                        <LayoutRows>
                            {/*
                                Temporary disabled (Jira card PRTL-1141)

                                <ContentBlock header={{title: {label: textHome("campaign_brief.title").toUpperCase(), icon: {name: "forum", outlined: true}}}}>
                                    <Box
                                        cssClass={"home__campaign-brief"}
                                        spacing={BoxSpacing.LARGE}
                                        onClick={() => UIEventManager.emit(ModalCampaignBriefUiEvent)}
                                    >
                                        <h3>{textHome("campaign_brief.brief_sirdata")}</h3>
                                        <Paragraph withSpacing>{textHome("campaign_brief.brief_sirdata_text")}</Paragraph>
                                        <div className="campaign-brief__button">
                                            <span>{textHome("campaign_brief.brief_cta")}</span>
                                            <Icon name="east"/>
                                        </div>
                                    </Box>
                                </ContentBlock>
                            */}
                            {latestNewses.length > 0 &&
                                <ContentBlock
                                    header={{title: {label: textHome("latest_newses.title").toUpperCase(), icon: {name: "article"}}}}
                                    footer={{link: "https://news.sirdata.com/", label: textHome("latest_newses.see_all_newses"), target: "_blank"}}
                                >
                                    <Box cssClass={"home__news"} {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                        <Loadable loading={isNewsesLoading} loaderOptions={{cssClass: "news__loader"}}>
                                            {latestNewses.map(({title, url, pubDate}) =>
                                                <div key={title} className="news__item">
                                                    <span className="news__item__date">{Formatter.formatDate(pubDate)}</span>
                                                    <ButtonLink
                                                        cssClass="news__item__title"
                                                        style={ButtonLinkStyle.MIDNIGHT_LIGHT}
                                                        onClick={() => window.open(url, "_blank")}
                                                        reverseUnderline
                                                    >
                                                        {title}
                                                    </ButtonLink>
                                                </div>
                                            )}
                                        </Loadable>
                                    </Box>
                                </ContentBlock>
                            }
                            <Loadable loading={isCustomInProgressLoading} loaderOptions={{cssClass: "home__inprogress__loader"}}>
                                {!!currentCustomCategories.length && (
                                    <ContentBlock
                                        header={{title: {label: textHome("current_categories.title", {count: currentCustomCategories.length}).toUpperCase(), icon: {name: "priority_high", cssClass: "home__icon-priority"}}}}
                                        footer={{link: pathContextualCustom, label: textHome("current_categories.see_my_categories")}}
                                    >
                                        <Box cssClass={"home__inprogress"} {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                            {currentCustomCategories.map((category) =>
                                                <div className="inprogress__item" key={category.id}>
                                                    <div className="inprogress__item__infos">
                                                        <span>{category.name}</span>
                                                        <IconTooltip
                                                            icon={Action.EDIT.icon}
                                                            text={textHome("current_categories.resume")}
                                                            cssClass="inprogress__item__infos__icon-button"
                                                            onClick={() => history.push(category.getRoute(CustomCategoryConfigStep.Keywords))}
                                                        />
                                                    </div>
                                                    <div className="inprogress__item__progress">
                                                        <div className="inprogress__item__progress__bars">
                                                            <span className="inprogress__item__progress__bars__back"/>
                                                            <span className="inprogress__item__progress__bars__track" style={{width: 33.333 + "%"}}/>
                                                        </div>
                                                        <span className="inprogress__item__progress__state">1/3 {textHome("current_categories.completed")}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </Box>
                                    </ContentBlock>
                                )}
                            </Loadable>
                            <ContentBlock header={{title: {label: textHome("support.title").toUpperCase(), icon: {name: "support"}}}}>
                                <Box cssClass={"home__support"} {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                    <div className="support__picture">
                                        <img src="/common/images/support/superdata.png" alt="Superdata"/>
                                    </div>
                                    <Paragraph withSpacing>{textHome("support.support_text")}</Paragraph>
                                    <Button size={ButtonSize.MEDIUM} style={ButtonStyle.PRIMARY_GREEN} onClick={() => UIEventManager.emit(ModalContactUiEvent, {})} icon={{name: "chat_bubble", outlined: true}}>
                                        {textHome("support.support_btn")}
                                    </Button>
                                </Box>
                            </ContentBlock>
                        </LayoutRows>
                    </FlexContent>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default Home;
